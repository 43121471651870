import httpClient from "../httpClient"
import { formatData } from "../request"
import { encodeRequestParameters } from "../request"
import { mfaAuthFormat, resetPasswordFormat } from "../formats"

/**
 * Renew token
 */
export function renew() {
    return httpClient
        .post("/renew")
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Log out
 */
export function logOut() {
    return httpClient
        .post("/logout")
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * MFA
 */

export function mfa(data) {
    data = formatData(mfaAuthFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .post("/auth/mfa", params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * reset password
 */

export function sendResetCodePassword(data) {
    data = formatData(resetPasswordFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .post("/password/user", params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function resetPassword(data) {
    data = formatData(resetPasswordFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .post("/password/change", params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
